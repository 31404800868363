import Login from './Component/Login'
import Dashboard from './Component/Dashboard'
import monitorDashboard from './Component/monitiorDasboard'
import Intermediate from './Component/intermediate'
// import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from './lib/privateRoute'
function App() {
 
  return (
    <BrowserRouter >
      <Switch>
        {/* <Route path="/dashboard" exact component={Login} /> */}
        <Route path="/" exact component={Dashboard} />
        <Route path="/credit-risk-monitoring" exact component={monitorDashboard} />
      </Switch>
    </BrowserRouter >
  );
}

export default (App);